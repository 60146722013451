/*=========================================================================================
  File Name: actions.js
  Description: Vuex Store - actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from 'axios'

const enhanceAccessToeken = () => {
  const {accessToken} = localStorage
  // console.log(accessToken);
  if (!accessToken) return
  axios.defaults.headers.common['token'] = `Bearer ${accessToken}`;
}
const enhanceBannerToeken = () => {
  const {bannertoken} = localStorage
  // console.log(accessToken);
  if (!bannertoken) return
  axios.defaults.headers.common['bannertoken'] = `Bearer ${bannertoken}`;
}
enhanceAccessToeken()
enhanceBannerToeken()

const actions = {
    LOGIN ({commit}, {id, password, remember}) {
      return axios.post(`/api/login`, {id, password})
        .then(({data}) => {
          commit('LOGIN', data.token);
          commit('USERSTATE', {user: data.user, remember, id});
          // console.log(data.token);
          axios.defaults.headers.common['token'] = `Bearer ${data.token}`;
        })
    },
    LOGOUT ({commit}) {
      axios.defaults.headers.common['token'] = undefined;
      commit('LOGOUT');
    },
    bannerManagerLOGIN ({commit}, {id, password}) {
      return axios.post(`/api/bannerManager/login`, {id, password})
        .then(({data}) => {
          commit('bannerManagerLOGIN', data.token);
          // console.log(data.token);
          axios.defaults.headers.common['bannertoken'] = `Bearer ${data.token}`;
        })
    },
    SetWriterAll ({commit}, {writerAll}){
      commit('SetWriterAll', writerAll);
    },
    setSeriesState({commit}, {seriesArray}){
      // console.log(seriesArray);
      commit('SEREIESEDIT', seriesArray);
    },

    // ////////////////////////////////////////////
    // SIDEBAR & UI UX
    // ////////////////////////////////////////////

    updateSidebarWidth({ commit }, width) {
        commit('UPDATE_SIDEBAR_WIDTH', width);
    },
    updateI18nLocale({ commit }, locale) {
        commit('UPDATE_I18N_LOCALE', locale);
    },
    toggleContentOverlay({ commit }) {
        commit('TOGGLE_CONTENT_OVERLAY');
    },
    updateTheme({ commit }, val) {
        commit('UPDATE_THEME', val);
    },


    // ////////////////////////////////////////////
    // COMPONENT
    // ////////////////////////////////////////////

    // VxAutoSuggest
    updateStarredPage({ commit }, payload) {
        commit('UPDATE_STARRED_PAGE', payload)
    },

    //  The Navbar
    arrangeStarredPagesLimited({ commit }, list) {
        commit('ARRANGE_STARRED_PAGES_LIMITED', list)
    },
    arrangeStarredPagesMore({ commit }, list) {
        commit('ARRANGE_STARRED_PAGES_MORE', list)
    },
}

export default actions
