/*=========================================================================================
  File Name: state.js
  Description: Vuex Store - state
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import navbarSearchAndPinList from '@/layouts/components/navbarSearchAndPinList'
import themeConfig from '@/../themeConfig.js'
import colors from '@/../themeConfig.js'

const state = {
    isSidebarActive: true,
    breakpoint: null,
    sidebarWidth: "default",
    reduceButton: themeConfig.sidebarCollapsed,
    bodyOverlay: false,
    sidebarItemsMin: false,
    theme: themeConfig.theme || 'light',
    navbarSearchAndPinList: navbarSearchAndPinList,
    AppActiveUser: {
        id: 0,
        name: '',
        writer: '',
        tail: '기자',
        levelText: '기자',
        level: '7',
        email: '',
        department: '',
    },
    themePrimaryColor: colors.primary,
    starredPages: navbarSearchAndPinList.data.filter((page) => page.highlightAction),
    
    accessToken: null,
    categories1: [],
    categories2: [],
    newCategories1: [],
    newCategories2: [],
    series: [],
    writer: [],
    writerAll: null,
    states: [
        {code: 9, name: '작성중'},
        {code: 8, name: '작성완료'},
        {code: 3, name: '편집완료'},
        {code: 2, name: '송고예약'},
        {code: 1, name: '송고완료'},
        {code: 0, name: '보류'},
    ],
    portals: [
        {'id': 0, 'name': 'Naver', 'oldName': 'mna'},
        {'id': 1, 'name': 'Daum', 'oldName': 'ndaum'},
        {'id': 2, 'name': 'ZUM', 'oldName': 'zum'},
        {'id': 3, 'name': 'Nate', 'oldName': 'nt'},
        {'id': 4, 'name': 'KU', 'oldName': 'ku'},
        // {'id': 5, 'name': 'BRIC', 'oldName': 'bric'},
    ],
    portalsWithAll: [
        {'id': 0, 'name': 'Naver', 'oldName': 'mna'},
        {'id': 1, 'name': 'Daum', 'oldName': 'ndaum'},
        {'id': 2, 'name': 'ZUM', 'oldName': 'zum'},
        {'id': 3, 'name': 'Nate', 'oldName': 'nt'},
        {'id': 4, 'name': 'KU', 'oldName': 'ku'},
        {'id': 9, 'name': 'ALL', 'oldName': '(none)'}
    ],
    maxWidth: 600,
    calendarLabels: [
        { text: '데스크' ,value : '데스크', color: 'success' },
        { text: '평일당직', value: '평일당직', color: 'warning'},
        { text: '주말공휴일재택', value: '주말공재', color: 'danger'},
        { text: '주말공휴일출근', value: '주말공출', color: 'primary'},
    ],
    timeOffLabels: [
        { text: '데스크' ,value : '데스크', color: 'success' },
        { text: '기자 및 일반직원', value: '기자 및 일반직원', color: 'primary'},
    ],
}

export default state
