export default {
  actionIcon: 'StarIcon',
  highlightColor: 'warning',
  data: [
    {index: 0, label: '일반회원 관리', url: '/member/common', labelIcon: 'UserIcon', highlightAction: false},
    {index: 0, label: '기자 관리', url: '/member/reporter', labelIcon: 'UserIcon', highlightAction: false},
    {index: 1, label: '뉴스리스트', url: '/newsList', labelIcon: 'CalendarIcon', highlightAction: false},
    {index: 1, label: '뉴스배치', url: '/newsPlace', labelIcon: 'LayoutIcon', highlightAction: false},
    {index: 1, label: '기사작성', url: '/writeArticles', labelIcon: 'ListIcon', highlightAction: false},
    {index: 1, label: '일정', url: '/calendar', labelIcon: 'CalendarIcon', highlightAction: false},
    {index: 1, label: '배너관리', url: '/banner', labelIcon: 'ArchiveIcon', highlightAction: false},
    {index: 1, label: '통계', url: '/statistics', labelIcon: 'PieChartIcon', highlightAction: false},
    {index: 1, label: '설정', url: '/setting', labelIcon: 'SettingsIcon', highlightAction: false},
  ]
}
