/*=========================================================================================
  File Name: main.js
  Description: main vue(js) file
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import Vue from 'vue'
import App from './App.vue'

// Vuesax Component Framework
import Vuesax from 'vuesax'
import 'material-icons/iconfont/material-icons.css' //Material Icons
import 'vuesax/dist/vuesax.css'; // Vuesax
Vue.use(Vuesax)
import VTooltip from 'v-tooltip';
Vue.use(VTooltip)
import CKEditor from '@ckeditor/ckeditor5-vue';
Vue.use(CKEditor);

import vSelect from 'vue-select'
Vue.component('v-select', vSelect)
import ToggleButton from 'vue-js-toggle-button'
Vue.use(ToggleButton)
import VueMaterial from 'vue-material'
import 'vue-material/dist/vue-material.min.css'
Vue.use(VueMaterial)
import Datepicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css';
Vue.component('datepicker', Datepicker);

// Theme Configurations
import '../themeConfig.js'


// Globally Registered Components
import './globalComponents.js'


// Styles: SCSS
import './assets/scss/main.scss'


// Tailwind
import '@/assets/css/main.css';


// Vue Router
import router from './router'


// Vuex Store
import store from './store/store'

// axios
import axios from 'axios'
Vue.prototype.$http = axios

// PrismJS
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'



// Feather font icon
require('./assets/css/iconfont.css')


Vue.config.productionTip = false

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
