import Vue from 'vue'
import Router from 'vue-router'
import store from './store/store'
import axios from 'axios'
Vue.use(Router)

const requireAuth = () => (to, from, next) => {
  console.log('to: ', to);
  if(store.state.AppActiveUser.id){ //유저정보가 있으면 토큰만 갱신
    console.log('user data OK')
    axios.get('/api/login/check')
    .then(data => {
      axios.defaults.headers.common['token'] = data.data.newtoken;
      store.commit('LOGIN', data.data.newtoken)
      return next();
    }).catch(function() {
      router.push({path: '/pages/login?to='+to.path, query: to.query})
    })
  }else{ //유저정보가 없으면 유저정보도 받아옴
    console.log('No user data')
    axios.get('/api/login/checkAndData')
    .then(data => {
      store.commit('LOGIN', data.data.newtoken)
      store.commit('USERSTATE', {user: data.data.user})
      axios.defaults.headers.common['token'] = data.data.newtoken;
      return next()
    }).catch(function() {
      router.push({path: '/pages/login?to='+to.path, query: to.query})
    })
  }
  // if (store.getters.isAuthenticated) {
  //   // console.log(store.getters.isAuthenticated)
  //   return next()
  // }
  // // console.log(store.getters.isAuthenticated)
  // router.push('/pages/login?to='+to.path)
}
const requireAuthBanner = () => (to, from, next) => {
  if(store.state.AppActiveUser.id){ //유저정보가 있으면 토큰만 갱신
    console.log('user data OK')
    axios.get('/api/bannerManager/login/check')
    .then(data => {
      axios.defaults.headers.common['token'] = data.data.newtoken;
      store.commit('bannerManagerLOGIN', data.data.newtoken)
      return next();
    }).catch(function() {
      router.push({path: '/bannerManager/login?to='+to.path, query: to.query})
    })
  }else{ //유저정보가 없으면 유저정보도 받아옴
    console.log('No user data')
    axios.get('/api/bannerManager/login/checkAndData')
    .then(data => {
      // console.log('check ok1')
      store.commit('bannerManagerLOGIN', data.data.newtoken)
      // console.log('check ok2')
      store.commit('bannerManagerUSERSTATE', data.data.user)
      // console.log('check ok3')
      axios.defaults.headers.common['bannertoken'] = data.data.newtoken;
      return next()
    }).catch(function() {
      // console.log('check fail')
      router.push({path: '/bannerManager/login?to='+to.path, query: to.query})
    })
  }
}
const logout = () => () => {
  if(store.state.AppActiveUser.level == 1) { store.dispatch('LOGOUT').then(() => {router.push('/bannerManager/login')}); }
  else { store.dispatch('LOGOUT').then(() => {router.push('/pages/login')}); }
}

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior () {
        return { x: 0, y: 0 }
    },
    routes: [

      {
    // =============================================================================  1
    // MAIN LAYOUT ROUTES
    // =============================================================================
        path: '',
        component: () => import('./layouts/main/Main.vue'),
        children: [
      // =============================================================================
      // Theme Routes
      // =============================================================================
          {
            path: '/',
            redirect: '/pages/login'
          },
          {
            path: '/center/',
            redirect: '/pages/login'
          },
          {
            path: '/newsList',
            redirect: '/newsListAll'
          },
          {
            path: '/ad/member',
            name: '광고사 관리',
            component: () => import('./views/AdMember.vue'),
            beforeEnter: requireAuth()
          },
          {
            path: '/ad/mains',
            name: '메인영역',
            component: () => import('./views/AdSourceMains.vue'),
            beforeEnter: requireAuth()
          },
          {
            path: '/ad/sides',
            name: '사이드바',
            component: () => import('./views/AdSourceMains.vue'),
            beforeEnter: requireAuth()
          },
          {
            path: '/ad/header',
            name: '상단',
            component: () => import('./views/AdSourceMains.vue'),
            beforeEnter: requireAuth()
          },
          {
            path: '/ad/footer',
            name: '하단',
            component: () => import('./views/AdSourceMains.vue'),
            beforeEnter: requireAuth()
          },
          {
            path: '/bannerManager/main',
            name: '메인영역',
            component: () => import('./views/AdSourceMains.vue'),
            beforeEnter: requireAuthBanner()
          },
          {
            path: '/bannerManager/sidebar',
            name: '사이드바',
            component: () => import('./views/AdSourceMains.vue'),
            beforeEnter: requireAuthBanner()
          },
          {
            path: '/bannerManager/header',
            name: '상단',
            component: () => import('./views/AdSourceMains.vue'),
            beforeEnter: requireAuthBanner()
          },
          {
            path: '/bannerManager/footer',
            name: '하단',
            component: () => import('./views/AdSourceMains.vue'),
            beforeEnter: requireAuthBanner()
          },
          {
            path: '/member/common',
            name: 'EBN 회원 관리',
            component: () => import('./views/MemberCommon.vue'),
            beforeEnter: requireAuth()
          },
          {
            path: '/member/reporter',
            name: '기자 관리',
            component: () => import('./views/MemberReporter.vue'),
            beforeEnter: requireAuth()
          },
          {
            path: '/member/individual',
            name: '개인 회원 관리',
            component: () => import('./views/MemberIndividual.vue'),
            beforeEnter: requireAuth()
          },
          {
            path: '/member/company',
            name: '기업 회원 관리',
            component: () => import('./views/MemberCompany.vue'),
            beforeEnter: requireAuth()
          },
          {
            path: '/newsListAll',
            name: '뉴스리스트',
            component: () => import('./views/NewsList.vue'),
            beforeEnter: requireAuth()
          },
          {
            path: '/newsListChem',
            name: '화학 뉴스리스트',
            component: () => import('./views/NewsList2.vue'),
            beforeEnter: requireAuth()
          },
          {
            path: '/newsListBada',
            name: '조선/물류 뉴스리스트',
            component: () => import('./views/NewsList3.vue'),
            beforeEnter: requireAuth()
          },
          {
            path: '/newsListSteel',
            name: '철강 뉴스리스트',
            component: () => import('./views/NewsList4.vue'),
            beforeEnter: requireAuth()
          },
          {
            path: '/dailianList',
            name: '데일리안 뉴스리스트',
            component: () => import('./views/DailianList.vue'),
            beforeEnter: requireAuth()
          },
          {
            path: '/yonhapList',
            name: '연합 뉴스리스트',
            component: () => import('./views/YonhapList.vue'),
            beforeEnter: requireAuth()
          },
          {
            path: '/photoArticle',
            name: '기사사진',
            component: () => import('./views/photoArticle.vue'),
            beforeEnter: requireAuth()
          },
          {
            path: '/photoDB',
            name: '포토DB',
            component: () => import('./views/photoDB.vue'),
            beforeEnter: requireAuth()
          },
          // {
          //   path: '/addPhoto',
          //   name: '포토등록',
          //   component: () => import('./views/addPhoto.vue'),
          //   beforeEnter: requireAuth()
          // },
          {
            path: '/newsPlace',
            name: '뉴스배치',
            component: () => import('./views/NewsPlace.vue'),
            beforeEnter: requireAuth()
          },
          {
            path: '/writeArticles',
            name: '기사작성',
            component: () => import('./views/WriteArticles.vue'),
            beforeEnter: requireAuth()
          },
          {
            path: '/setting',
            name: '설정',
            component: () => import('./views/Setting.vue'),
            beforeEnter: requireAuth()
          },
          {
            path: '/statistics/desc',
            name: '통계 조회수 순',
            component: () => import('./views/StatisticsDesc.vue'),
            beforeEnter: requireAuth()
          },
          {
            path: '/statistics/eachReporter',
            name: '통계 작성자별',
            component: () => import('./views/StatisticsEachReporter.vue'),
            beforeEnter: requireAuth()
          },
          {  
            path: '/statistics/myOwn',
            name: '통계',
            component: () => import('./views/StatisticsDescMyOwn.vue'),
            beforeEnter: requireAuth()
          },
          {
            path: '/board',
            name: '게시판',
            component: () => import('./views/board.vue'),
            beforeEnter: requireAuth()
          },
          {
            path: '/writePost',
            name: '게시판 글작성',
            component: () => import('./views/writePost.vue'),
            beforeEnter: requireAuth()
          },
          {
            path: '/calendar',
            name: '일정',
            component: () => import('./views/Calendar.vue'),
            beforeEnter: requireAuth()
          },
          {
            path: '/RequestingTimeOff',
            name: '일정',
            component: () => import('./views/RequestingTimeOff.vue'),
            beforeEnter: requireAuth()
          },
          {
            path: '/pages/logout',
            name: '로그아웃',
            beforeEnter: logout()
          },
        ],
      },
    // =============================================================================
    // FULL PAGE LAYOUTS
    // =============================================================================
      {
        path: '',
        component: () => import('@/layouts/full-page/FullPage.vue'),
        children: [
      // =============================================================================
      // PAGES
      // =============================================================================
          {
            path: '/pages/login',
            name: 'pageLogin',
            component: () => import('@/views/pages/Login.vue')
          },
          {
            path: '/pages/error-404',
            name: 'pageError404',
            component: () => import('@/views/pages/Error404.vue')
          },
          {
            path: '/bannerManager/login',
            name: 'bannerLogin',
            component: () => import('@/views/bannerManager/Login.vue')
          },
          {
            path: '/pages/overtimeStat',
            name: 'overtimestat',
            component: () => import('@/views/pages/OvertimeStat.vue')
          },
        ]
      },
      // Redirect to 404 page, if no match found
      {
        path: '*',
        redirect: '/pages/error-404'
      }
    ],
})

router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
    if (appLoading) {
        appLoading.style.display = "none";
    }
})

// router.beforeEach((to, from, next) => {
//   // if (store.getters.isAuthenticated) return next()
//   // router.push({ path: '/pages/login', query: { to: to.path } })
//   // router.push('/pages/login')
//   return next()
// });

export default router
